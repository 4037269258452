import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import { container, mainLogoImg } from './layout.module.css'
import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
    children?: React.ReactNode
}

const Layout = ({ children }: Props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <div className={container}>
            <Helmet>
                {/* <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                    crossOrigin="anonymous" /> */}
            </Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <main>
                <StaticImage
                    alt="Eldon Logo"
                    src="../images/eldon-healthcare-logo.webp"
                    className={mainLogoImg}
                />                 
                {children}
            </main>
            <p className='mt-3' style={{ fontSize: "1em", textAlign: "center"}}>Powered by <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC7ElEQVRYhbWXS0wTURSGv5kKyCPQEMNDw6MkIBgxMWJM3IDEFFNNtHGBkLA2GiOJJMaN7nGjbgxLiYkYFkYTgoYFulAXukCU8GoMDwkpVaBQWmppOy46RWjvtL0Vv92cuef+/8w998wdBSMGh44CHUATUAOYDceKcQPTwDvgKTbrmGiQIhAuBx4BlyQFk/ES6MRmnTc2MDh0DuhD/mlTxQ20YbO+iTcQEX8FZP4n8SgB4GLUhKKLVwIj/L8nj8UNHMdmnVX1wIN0xA9uBGj+sU7Vml821axroujV/k0qXdO4+2mRztElTBpoQF9NITcbKwip8XWdgHqVyFaT4sKMm1tfIuIQWcf26RWujrlkp+pQiexzKc7PronjM+J4AppUoFY2KzcYFsZzgiHZqWpVIF82yxip9QfIV5OPSR0tjZx9e2mgdnWTD/3j29feDJUBi5nHx4oJGuyOhAZaS4qxlxSRZzJtxzonpgzHZ4c0jqzu7gknXT7qVvxca66UM9B9uJrbVfFJ9xzfE3kW0upY4f6JUmYKsuLuCWvgUFYWXZYKaSEjFKDc81t4T2igLi8XkyJd0YZsKTBRmJ26AYfPR1hLp6bj0YDuhlJcORnC+8IamNv00zO/wPWKMmHSx5I8/Kbkb2gjw8SAxcxwmXGrMSzCG+OTjHo82It374Lq3BzGGmuIPV8tB7aY8HqTmopFYXBI6l1/Pn2KhoL4J3rhXOLyyFdpA3vaCdNBJXI6SZm92xsAuFVgUibDEwwK4+vyX0KASRUYlsnody7FxTRNo9/pTMfAsAr0AuIPvICe+QUezs4R1PuEPxTmzpSD1z+XZcXDQG/0VNwHXJHJLsrMxJK9nymvD7fBsiThOTZrW7QPdAFngQOpZrsCAVyBQDrCAL90TX0b2qyLgB3wpTujBD7Armvu6AM263ugBZA+2krgAlp0LXYb+GuiHngCpLWwBgT1Oet3ikOivhL5S24HzvBvv+dvgWexf8VR/gCElt2rcpyMnAAAAABJRU5ErkJggg==" alt="Walnut" width="18" height="20" /> Walnut Plus CRM </p>
        </div>
    )
}

export default Layout