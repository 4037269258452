// extracted by mini-css-extract-plugin
export var clickToEnlargeText = "layout-module--click-to-enlarge-text--ZGVLf";
export var container = "layout-module--container--eLBMS";
export var externalComponentControlStyle = "layout-module--external-component-control-style--kkIAB";
export var formTitleLabel = "layout-module--form-title-label--KkWvW";
export var headerTitle = "layout-module--header-title--Ixffn";
export var inputFormContainer = "layout-module--input-form-container--tSjgE";
export var mainLogoImg = "layout-module--main-logo-img--zUm-T";
export var polygonVerticalCenterHorizontalRight = "layout-module--polygon-vertical-center-horizontal-right--6GO35";
export var productInfoText = "layout-module--product-info-text--5-bDd";
export var productListCard = "layout-module--product-list-card--pe2Zw";
export var productListModal = "layout-module--product-list-modal--aX1Km";
export var productListModalSelectProductModelText = "layout-module--product-list-modal-select-product-model-text--YtbPG";
export var receiptStaticImage = "layout-module--receipt-static-image--WB9e7";
export var walnutPrimaryButton = "layout-module--walnut-primary-button--6Llmv";
export var walnutSecondaryButton = "layout-module--walnut-secondary-button--lNEym";
export var wrongModelText = "layout-module--wrong-model-text--RlKh2";